import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const AdvertisingPage = ({ data }) => (
  <Layout activePage="advertising">
    <SEO title="Advertising" keywords="Frank Kürzel, Model, Advertising, Werbung, Werbeanzeigen" />
    <div className="card-columns">
      {data.allFile.edges.map(({ node }, index) => (
        <div className="card" key={index}>
          <Image imgName={node.base} />
        </div>
      ))}
    </div>
  </Layout>
)

export default AdvertisingPage

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/advertising/" }
      }
    ) {
      edges {
        node {
          base
        }
      }
    }
  }
`
